//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
// APES
const firebaseConfig = {
  apiKey: 'AIzaSyC6ihgZaEORfitRmOhqvwgdtAUQgCRphKw',
  authDomain: 'apes-f79c3.firebaseapp.com',
  projectId: 'apes-f79c3',
  storageBucket: 'apes-f79c3.appspot.com',
  messagingSenderId: '561208021465',
  appId: '1:561208021465:web:ac5ad3dc3d742b1a5809c8',
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Session persistence set to LOCAL');
  })
  .catch((error) => {
    console.error('Failed to set session persistence:', error);
  });

export { auth };
export default firebase_app;
